import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TimeOffBalanceModel } from './time-off-balance.model';

export interface TimeOffBalanceState extends EntityState<TimeOffBalanceModel> {
  isLoading?: boolean;
  error?: any;
}

export const timeOffBalanceAdapter: EntityAdapter<TimeOffBalanceModel> = createEntityAdapter<TimeOffBalanceModel>({});

export const initialState: TimeOffBalanceState = timeOffBalanceAdapter.getInitialState({
  isLoading: false,
  error: null,
});
