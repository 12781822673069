import { AbsenceOptionUnit } from '@app/enums';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { SelectItem } from '@sb/ui';

import { TreeviewItem } from '../../../shared/ngx-treeview/treeview-item';
import { AppState } from '../../index';
import {
  selectPoliciesWithAbsenceType,
  selectPolicyConfigurationById,
} from '../absence-policy/absence-policy.selectors';
import { getAbsenteeOptionById } from '../absentee-option/absentee-option.service';
import { TimeOffBalanceModel } from './time-off-balance.model';
import { timeOffBalanceAdapter, TimeOffBalanceState } from './time-off-balance.state';

export const getTimeOffBalanceState = (appState: AppState): TimeOffBalanceState => appState.orm.timeOffBalances;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  timeOffBalanceAdapter.getSelectors(getTimeOffBalanceState);

export const getTimeOffBalances = createSelector(
  selectAll,
  (timeOffBalances: TimeOffBalanceModel[]): TimeOffBalanceModel[] => timeOffBalances,
);

export const getActiveTimeOffBalances = createSelector(
  getTimeOffBalances,
  (timeOffBalances: TimeOffBalanceModel[]): TimeOffBalanceModel[] =>
    timeOffBalances.filter((timeOffBalance: TimeOffBalanceModel) => timeOffBalance.active),
);

export const getTimeOffBalanceOptionsForCorrection = (excludeBalanceId: string) =>
  createSelector(getActiveTimeOffBalances, (timeOffBalances: TimeOffBalanceModel[]) => {
    const filteredBalances = timeOffBalances.filter(
      (timeOffBalance: TimeOffBalanceModel) => timeOffBalance.id !== excludeBalanceId,
    );

    return {
      balances: timeOffBalances,
      filteredBalances,
    };
  });
export const getTimeOffBalanceEntities = createSelector(
  selectEntities,
  (timeOffBalanceEntities: Dictionary<TimeOffBalanceModel>): Dictionary<TimeOffBalanceModel> => timeOffBalanceEntities,
);

export const getTimeOffBalanceById = (id: string) =>
  createSelector(
    selectEntities,
    (timeOffBalanceEntities: Dictionary<TimeOffBalanceModel>) => timeOffBalanceEntities[id],
  );

export const getTimeOffBalanceOptionsWithUnit = (unit: AbsenceOptionUnit) =>
  createSelector(getActiveTimeOffBalances, (timeOffBalances): SelectItem[] =>
    timeOffBalances
      .filter((timeOffBalance) => timeOffBalance.unit === unit)
      .map(
        (timeOffBalance): SelectItem => ({
          text: timeOffBalance.name,
          value: timeOffBalance.id,
          disabled: false,
        }),
      ),
  );

export const getTimeOffBalanceOptions = createSelector(getActiveTimeOffBalances, (timeOffBalances): TreeviewItem[] =>
  timeOffBalances.map(
    (timeOffBalance) =>
      new TreeviewItem({
        text: timeOffBalance.name,
        value: timeOffBalance.id,
        disabled: false,
      }),
  ),
);

export const getTimeOffBalancesForAbsenteeOptionInAbsencePolicies = (optionId: string) =>
  createSelector(
    getAbsenteeOptionById(optionId),
    getActiveTimeOffBalances,
    selectPoliciesWithAbsenceType(optionId),
    (absenteeOption, timeOffBalances, absencePolicies): SelectItem[] => {
      if (!absenteeOption) {
        return [];
      }

      return timeOffBalances
        .filter((timeOffBalance) =>
          absencePolicies.some((policy) => {
            const policyBalanceIds =
              policy.configuration.find((absenceType) => absenceType.absenceTypeId === optionId)?.balanceIds || [];
            return policyBalanceIds.includes(timeOffBalance.id);
          }),
        )
        .map(
          (timeOffBalance): SelectItem => ({
            text: timeOffBalance.name,
            value: timeOffBalance.id,
          }),
        );
    },
  );

export const getTimeOffBalancesForAbsencePolicy = (
  absencePolicyId: string,
  optionId: string,
  includeTimeOffBalanceIds: string[] = [],
) =>
  createSelector(
    getAbsenteeOptionById(optionId),
    selectPolicyConfigurationById(absencePolicyId, optionId),
    getTimeOffBalances,
    (absenteeOption, policyConfiguration, timeOffBalances): SelectItem<string>[] => {
      if (!absenteeOption) {
        return [];
      }

      const balanceIds = policyConfiguration?.balanceIds || [];

      return timeOffBalances
        .filter((timeOffBalance: TimeOffBalanceModel) => {
          if (includeTimeOffBalanceIds?.length > 0 && includeTimeOffBalanceIds.includes(timeOffBalance.id)) {
            return true;
          }
          return timeOffBalance.active && balanceIds.includes(timeOffBalance.id);
        })
        .map(
          (timeOffBalance): SelectItem<string> => ({
            text: timeOffBalance.name,
            value: timeOffBalance.id,
          }),
        );
    },
  );

export const getReportableTimeOffBalanceOptions = createSelector(
  getActiveTimeOffBalances,
  (timeOffBalances: TimeOffBalanceModel[]) =>
    timeOffBalances.map((timeOffBalance: TimeOffBalanceModel) => ({
      value: timeOffBalance.id,
      text: timeOffBalance.name,
    })),
);
