import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AbsenteeOptionModel } from '../absentee-option/absentee-option.model';
import { getAbsenteeOptionById, getAbsenteeOptions } from '../absentee-option/absentee-option.service';
import { getActiveContractTypes } from '../contract-type/contract-type.service';
import { selectOrm } from '../orm.selectors';
import { getTimeOffBalanceEntities } from '../time-off-balance/time-off-balance.selectors.ts';
import { AbsencePolicyConfigurationFullModel, AbsencePolicyModel } from './absence-policy.model';
import { adapter } from './absence-policy.state';

export const featureKey = 'absencePolicies';

export const selectAbsencePolicies = createSelector(selectOrm, (state) => state[featureKey]);

export const { selectAll, selectEntities } = adapter.getSelectors(selectAbsencePolicies);

export const selectAllAbsencePoliciesWithConfiguration = createSelector(selectAll, (policies) =>
  policies.filter((policy) => policy?.configuration?.length),
);

export const selectAllWithContactTypeUsage = createSelector(
  selectAll,
  getActiveContractTypes,
  (policies, contractTypes) =>
    policies.map(
      (policy): AbsencePolicyModel => ({
        ...policy,
        contractTypeIds: contractTypes.reduce(
          (contractTypeUsage: string[], contractType) =>
            contractType.absence_policy_id === policy.id && !contractTypeUsage.includes(contractType.id)
              ? [...contractTypeUsage, contractType.id]
              : contractTypeUsage,
          [],
        ),
      }),
    ),
);

export const selectIsAdding = createSelector(selectAbsencePolicies, (state) => state.isAdding);

export const selectPolicyById = (policyId: string) => createSelector(selectEntities, (entities) => entities[policyId]);

export const selectPolicyConfigurationById = (policyId: string, absenceTypeId: string) =>
  createSelector(
    selectPolicyById(policyId),
    (policy) => policy?.configuration?.find((config) => config.absenceTypeId === absenceTypeId),
  );

export const selectPolicyLoadingById = (policyId: string) =>
  createSelector(selectAbsencePolicies, (state) => state.loading[policyId]);

export const selectPolicyUpdatingById = (policyId: string) =>
  createSelector(selectAbsencePolicies, (state) => state.updating[policyId]);

export const selectIsLoading = createSelector(selectAbsencePolicies, (state) => state.isLoading);

export const selectPolicyConfigurationFullById = (
  policyId: string,
): MemoizedSelector<object, AbsencePolicyConfigurationFullModel[]> =>
  createSelector(
    selectPolicyById(policyId),
    getTimeOffBalanceEntities,
    getAbsenteeOptions,
    (policy, balanceEntities, absenceTypes: AbsenteeOptionModel[]) =>
      absenceTypes.map((absenceType) => {
        const configuration = policy?.configuration?.find((config) => config.absenceTypeId === absenceType.id);
        return {
          absenceType,
          balances: (configuration?.balanceIds || []).map((balanceId) => balanceEntities[balanceId]),
          enabled: !!configuration,
        };
      }),
  );

export const selectPoliciesWithAbsenceType = (absenceTypeId: string) =>
  createSelector(
    selectAllAbsencePoliciesWithConfiguration,
    getAbsenteeOptionById(absenceTypeId),
    (policies, absenceType: AbsenteeOptionModel) =>
      policies.filter((policy) =>
        policy.configuration.map((absenceType) => absenceType.absenceTypeId).includes(absenceType.id),
      ),
  );
