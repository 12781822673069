import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AbsencePolicyModel } from './absence-policy.model';

export interface AbsencePolicyState extends EntityState<AbsencePolicyModel> {
  isAdding: boolean;
  loading: { [key: string]: boolean };
  updating: { [key: string]: boolean };
  isLoading: boolean;
}

export const adapter: EntityAdapter<AbsencePolicyModel> = createEntityAdapter<AbsencePolicyModel>({});

export const initialState: AbsencePolicyState = adapter.getInitialState({
  isAdding: false,
  loading: {},
  updating: {},
  isLoading: false,
});
